<template>
  <CLoading :loading="loading" />
  <q-table
    flat
    dense
    title="Scenario Templates"
    v-if="scenario_templates"
    :rows="scenario_templates"
    row-key="title"
    bordered
    ref="welcomeList"
    :rows-per-page-options="[10]"
    separator="cell"
    :no-data-label="$t('messages.no_data_available')"
    class="text-grey-8"
  >
    <template v-slot:header="props">
      <q-th key="index" rowspan="2" class="text-left" style="width: 100px"></q-th>
      <q-th :props="props" key="title">
        <span class="text-bold">{{ $t('label.scenario_template.template_name') }}</span>
      </q-th>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="action" :props="props.index">
          <q-btn size="sm" flat round icon="edit" :to="onEdit(props.row)" class="q-mr-sm" />
          <q-btn size="sm" flat round icon="content_copy" @click="onCopy(props.row)" class="q-mr-sm" />
          <q-btn size="sm" flat round color="red" icon="delete" @click="onDelete(props.row)" class="q-mr-sm" />
        </q-td>
        <q-td key="title" :props="props">
          {{ props.row.title }}
        </q-td>
      </q-tr>
    </template>

    <template v-slot:top>
      <h2 class="text-h6 q-mr-md text-black">{{ $t('label.scenario_template.template_list') }}</h2>
      <q-space />
      <q-btn no-caps class="btn-save" @click="onAdd" :label="$t('add_new')" />
    </template>
  </q-table>
</template>

<script lang="ts">
import CLoading from '@/components/common/ui/CLoading.vue'
import { mixins, Options } from 'vue-class-component'
import ScenarioMixin from '@/components/scenario-template/minxins/ScenarioMixin.vue'
import { maska } from 'maska'
import { IScenarioTemplate } from '@/utils/types'
import { Watch } from 'vue-property-decorator'

import { ACTION_SCENARIO_TEMPLATE } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import { constant } from '@/utils/constants'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: {
    CLoading,
  },
  directives: { maska },
  emits: [],
})
export default class ScenarioList extends mixins(ScenarioMixin, BaseFormMixin) {
  scenario_templates: IScenarioTemplate[] = []
  loading = true

  onAdd() {
    this.goto('scenario_template_setting', { app_id: this.selectedAppId, action_type: constant.ACTION_TYPE.ADD })
  }

  onEdit(data: IScenarioTemplate) {
    return this.detailPath('scenario_template_setting', {
      app_id: this.selectedAppId,
      scenario_template_id: data._id,
      action_type: constant.ACTION_TYPE.EDIT,
    })
  }

  onCopy(data: IScenarioTemplate) {
    this.goto('scenario_template_setting', {
      app_id: this.selectedAppId,
      scenario_template_id: data._id,
      action_type: constant.ACTION_TYPE.COPY,
    })
  }

  @Watch('$store.getters.scenarioTemplates')
  scenarioTemplateChanged() {
    const getScenarios = cloneDeep(this.$store.getters.scenarioTemplates)
    if (getScenarios) {
      return (this.scenario_templates = getScenarios)
    }
  }

  @Watch('selectedAppId', { immediate: true })
  async appIdChanged() {
    if (!this.selectedAppId) {
      return
    }

    this.loading = true

    await this.$store.dispatch(ACTION_SCENARIO_TEMPLATE.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    this.loading = false
  }
}
</script>

<style scoped>
</style>
