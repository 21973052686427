<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="width: 1100px; max-width: 90vw">
      <q-toolbar>
        <q-toolbar-title>{{ modalTitle }}</q-toolbar-title>
        <q-btn flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section>
        <div>
          <q-form ref="autoResponseRef" greedy>
            <label class="text-grey-9 text-bold q-mt-sm">{{ $t('label.auto_response.title') }}</label>
            <q-input
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || requiredRule.title]"
              outlined
              v-model="form.title"
              dense
              :placeholder="$t('label.auto_response.title')"
            >
            </q-input>
            <div class="q-my-md">
              <label class="text-grey-9 text-bold">{{ $t('label.auto_response.response_type') }}</label>
              <q-list bordered class="bg-grey-2">
                <q-item tag="label" v-ripple>
                  <q-item-section avatar>
                    <q-radio v-model="form.response_type" :val="fixResponse" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>{{ $t('label.auto_response.fix_response') }}</q-item-label>
                    <q-item-label caption>{{ $t('label.auto_response.fix_response_description') }} </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item v-if="form.response_type === fixResponse">
                  <q-item-section>
                    <q-input
                      outlined
                      dense
                      v-model="exclusiveKeyword"
                      @keydown.enter="saveExclusiveKeyword"
                      :label="$t('label.auto_response.enter_exclusive_keyword')"
                    >
                      <template #prepend>
                        <q-chip
                          v-for="keyword in form.response_exclusive_keywords"
                          :key="keyword"
                          removable
                          @remove="removeExclusiveKeyword(keyword)"
                          color="primary"
                          text-color="white"
                        >
                          {{ keyword }}
                        </q-chip>
                      </template>
                    </q-input>
                  </q-item-section>
                </q-item>
                <q-item tag="label" v-ripple>
                  <q-item-section avatar>
                    <q-radio v-model="form.response_type" :val="keywordResponse" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>{{ $t('label.auto_response.keyword_response') }}</q-item-label>
                    <q-item-label caption>{{ $t('label.auto_response.keyword_response_description') }} </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item v-if="form.response_type === keywordResponse">
                  <q-item-section>
                    <q-input
                      outlined
                      dense
                      v-model="keyword"
                      @keydown.enter="saveKeyword"
                      :rules="[keywordRule]"
                      :label="$t('label.auto_response.enter_keyword')"
                    >
                      <template #prepend>
                        <q-chip
                          v-for="keyword in form.response_keywords"
                          :key="keyword"
                          removable
                          @remove="removeKeyword(keyword)"
                          color="primary"
                          text-color="white"
                        >
                          {{ keyword }}
                        </q-chip>
                      </template>
                    </q-input>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
            <div class="q-my-md">
              <label class="text-grey-9 text-bold">{{ $t('label.auto_response.use_schedule') }}</label>
              <q-list bordered class="bg-grey-2">
                <q-item>
                  <q-item-section side top>
                    <q-toggle v-model="form.is_schedule" :label="$t('label.auto_response.use_scheduling')" />
                    <q-item-label caption class="text-caption-hint">{{
                      $t('label.auto_response.use_scheduling_description')
                    }}</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item v-if="form.is_schedule" v-ripple style="min-height: 60px; padding-bottom: 0px">
                  <q-item-section avatar>
                    <q-radio v-model="form.schedule_type" :val="dateSchedule" />
                  </q-item-section>
                  <q-item-section>
                    <div class="q-gutter-xs">
                      <q-input
                        type="date"
                        max="2099-12-31"
                        mask="date"
                        fill-mask
                        clearable
                        outlined
                        dense
                        class="col-4"
                        v-model="form.from_date"
                        :disable="!form.is_schedule || form.schedule_type !== dateSchedule"
                        :rules="
                          form.schedule_type === dateSchedule
                            ? [(val) => (val && val.length > 0) || requiredRule.from]
                            : []
                        "
                        :style="form.schedule_type == 'time' ? '' : 'padding-bottom: 0px'"
                      >
                      </q-input>
                    </div>
                  </q-item-section>
                  <q-item-section>
                    <div class="q-gutter-xs">
                      <q-input
                        type="date"
                        max="2099-12-31"
                        mask="date"
                        fill-mask
                        clearable
                        outlined
                        dense
                        class="col-4"
                        v-model="form.to_date"
                        :disable="!form.is_schedule || form.schedule_type !== dateSchedule"
                        :rules="
                          form.schedule_type === dateSchedule
                            ? [(val) => (val && val.length > 0) || requiredRule.to]
                            : []
                        "
                        :style="form.schedule_type == 'time' ? '' : 'padding-bottom: 0px'"
                      >
                      </q-input>
                    </div>
                  </q-item-section>
                  <q-item-section> </q-item-section>
                </q-item>
                <q-item v-if="form.is_schedule" v-ripple style="min-height: 60px; padding-top: 0px">
                  <q-item-section avatar>
                    <q-radio v-model="form.schedule_type" :val="timeSchedule" />
                  </q-item-section>
                  <q-item-section>
                    <div class="q-gutter-xs">
                      <q-input
                        type="time"
                        mask="time"
                        fill-mask
                        clearable
                        outlined
                        dense
                        class="col-4"
                        v-model="form.from_time"
                        :disable="!form.is_schedule || form.schedule_type !== timeSchedule"
                        :rules="
                          form.schedule_type === timeSchedule
                            ? [(val) => (val && val.length > 0) || requiredRule.from]
                            : []
                        "
                        :style="form.schedule_type == 'date' ? '' : 'padding-bottom: 0px'"
                      >
                      </q-input>
                    </div>
                  </q-item-section>
                  <q-item-section>
                    <div class="q-gutter-xs">
                      <q-input
                        type="time"
                        mask="time"
                        fill-mask
                        clearable
                        outlined
                        dense
                        class="col-4"
                        v-model="form.to_time"
                        :disable="!form.is_schedule || form.schedule_type !== timeSchedule"
                        :rules="
                          form.schedule_type === timeSchedule
                            ? [(val) => (val && val.length > 0) || requiredRule.to]
                            : []
                        "
                        :style="form.schedule_type == 'date' ? '' : 'padding-bottom: 0px'"
                      >
                      </q-input>
                    </div>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>{{ $t('label.auto_response.every_day') }}</q-item-label></q-item-section
                  >
                </q-item>
              </q-list>
            </div>
            <label class="text-grey-9 text-bold q-mt-sm">{{ $t('label.auto_response.message_settings') }}</label>
            <InputEmojiSelector
              type="textarea"
              v-model="form.message"
              :placeholder="$t('label.auto_response.message_settings')"
              ref="replyMessage"
              :rules="[(val) => (val && val.length > 0) || requiredRule.message]"
            />
          </q-form>
        </div>
        <div class="row no-wrap items-center q-mt-md">
          <q-space />
          <div class="q-gutter-sm">
            <q-btn no-caps flat class="btn-cancel" @click="onCancel">{{ $t('cancel') }}</q-btn>
            <q-btn no-caps class="btn-save" @click="onSubmit"> {{ $t('save') }} </q-btn>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import BaseFormDialogMixin from '@/components/common/mixins/BaseFormDialogMixin.vue'
import { Prop } from 'vue-property-decorator'
import { IAutoResponse } from '@/utils/types'
import cloneDeep from 'lodash/cloneDeep'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'
import { maska } from 'maska'
import { ACTION_AUTO_RESPONSE } from '@/store/actions'
import { AUTO_RESPONSE } from '@/utils/constants'

@Options({
  components: { InputEmojiSelector },
  directives: { maska },
})
export default class AutoResponseFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  data!: IAutoResponse

  keyword = ''
  exclusiveKeyword = ''

  form: IAutoResponse = {
    _id: '',
    app_id: '',
    title: '',
    response_type: AUTO_RESPONSE.FIX_RESPONSE,
    response_keywords: [],
    response_exclusive_keywords: [],
    is_schedule: false,
    schedule_type: AUTO_RESPONSE.DATE_SCHEDULE,
    from_date: '',
    to_date: '',
    from_time: '',
    to_time: '',
    message: '',
    in_use: true,
  }

  fixResponse = AUTO_RESPONSE.FIX_RESPONSE
  keywordResponse = AUTO_RESPONSE.KEYWORD_RESPONSE
  dateSchedule = AUTO_RESPONSE.DATE_SCHEDULE
  timeSchedule = AUTO_RESPONSE.TIME_SCHEDULE

  get modalTitle() {
    return this.isNew ? this.$t('label.auto_response.add_new_message') : this.$t('label.auto_response.edit_message')
  }

  get requiredRule() {
    return {
      message: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.messages.message'),
      }),
      title: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.auto_response.title'),
      }),
      from: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.app.from'),
      }),
      to: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.app.to'),
      }),
    }
  }

  keywordRule() {
    let error = false
    if (this.form.response_type === this.keywordResponse) {
      if (!this.form.response_keywords?.length) {
        error = true
      }
    }
    if (error) {
      return (
        false ||
        this.$t('validate.this_field_is_required', {
          placeholder: this.$t('label.auto_response.keywords'),
        })
      )
    }

    return true || ''
  }

  saveKeyword() {
    if (this.keyword) {
      if (this.form.response_keywords) {
        this.form.response_keywords.push(this.keyword)
        this.keyword = ''
      }
    }
  }

  removeKeyword(keyword) {
    if (this.form.response_keywords) {
      this.form.response_keywords = this.form.response_keywords.filter((item) => item !== keyword)
    }
  }

  saveExclusiveKeyword() {
    if (this.exclusiveKeyword) {
      if (this.form.response_exclusive_keywords) {
        this.form.response_exclusive_keywords.push(this.exclusiveKeyword)
        this.exclusiveKeyword = ''
      }
    }
  }

  removeExclusiveKeyword(keyword) {
    if (this.form.response_exclusive_keywords) {
      this.form.response_exclusive_keywords = this.form.response_exclusive_keywords.filter((item) => item !== keyword)
    }
  }

  onSubmit() {
    this.$refs.autoResponseRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }
        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let autoResponse = null
    if (this.isNew) {
      autoResponse = await dispatch(ACTION_AUTO_RESPONSE.ADD_NEW, {
        ...this.form,
      })
    } else {
      autoResponse = await dispatch(ACTION_AUTO_RESPONSE.UPDATE, {
        ...this.form,
      })
    }
    if (autoResponse) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
      this.visible = false
    }
  }

  mounted() {
    this.$nextTick(() => {
      this.$refs.autoResponseRef.validate()
    })
  }

  async created() {
    this.form = { ...this.form, ...cloneDeep(this.data) }
    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
  }
}
</script>

<style scoped lang="scss">
</style>
