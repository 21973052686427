
import CLoading from '@/components/common/ui/CLoading.vue'
import { mixins, Options } from 'vue-class-component'
import ScenarioMixin from '@/components/scenario-template/minxins/ScenarioMixin.vue'
import { maska } from 'maska'
import { IScenarioTemplate } from '@/utils/types'
import { Watch } from 'vue-property-decorator'

import { ACTION_SCENARIO_TEMPLATE } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import { constant } from '@/utils/constants'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: {
    CLoading,
  },
  directives: { maska },
  emits: [],
})
export default class ScenarioList extends mixins(ScenarioMixin, BaseFormMixin) {
  scenario_templates: IScenarioTemplate[] = []
  loading = true

  onAdd() {
    this.goto('scenario_template_setting', { app_id: this.selectedAppId, action_type: constant.ACTION_TYPE.ADD })
  }

  onEdit(data: IScenarioTemplate) {
    return this.detailPath('scenario_template_setting', {
      app_id: this.selectedAppId,
      scenario_template_id: data._id,
      action_type: constant.ACTION_TYPE.EDIT,
    })
  }

  onCopy(data: IScenarioTemplate) {
    this.goto('scenario_template_setting', {
      app_id: this.selectedAppId,
      scenario_template_id: data._id,
      action_type: constant.ACTION_TYPE.COPY,
    })
  }

  @Watch('$store.getters.scenarioTemplates')
  scenarioTemplateChanged() {
    const getScenarios = cloneDeep(this.$store.getters.scenarioTemplates)
    if (getScenarios) {
      return (this.scenario_templates = getScenarios)
    }
  }

  @Watch('selectedAppId', { immediate: true })
  async appIdChanged() {
    if (!this.selectedAppId) {
      return
    }

    this.loading = true

    await this.$store.dispatch(ACTION_SCENARIO_TEMPLATE.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    this.loading = false
  }
}
