<template>
  <CLoading :loading="loading" />
  <q-table
    bordered
    flat
    dense
    title="Treats"
    ref="welcomeList"
    v-if="stories"
    :rows="stories"
    row-key="title"
    :rows-per-page-options="[10]"
    separator="cell"
    :no-data-label="$t('messages.no_data_available')"
    class="text-grey-8"
  >
    <template v-slot:header="props">
      <q-tr>
        <q-th key="action" class="text-center" style="width: 50px">
          <span class="text-bold">{{ $t('label.prod') }}</span>
        </q-th>
        <q-th key="action" class="text-center" style="width: 50px">
          <span class="text-bold">{{ $t('label.test') }}</span>
        </q-th>
        <q-th :props="props" key="trigger_type" style="width: 100px">
          <span class="text-bold">{{ $t('label.story.trigger') }}</span>
        </q-th>
        <q-th key="edit" class="text-center" style="width: 50px"> </q-th>
        <q-th :props="props" key="story_name">
          <span class="text-bold">{{ $t('label.story.story_name') }}</span>
        </q-th>
        <q-th :props="props" key="story_name">
          <span class="text-bold">{{ $t('label.story.start_story_count') }}</span>
        </q-th>
        <q-th :props="props" key="story_name">
          <span class="text-bold">{{ $t('label.story.send_qa_count') }}</span>
        </q-th>
        <q-th :props="props" key="story_name">
          <span class="text-bold">{{ $t('label.story.answered_qa_count') }}</span>
        </q-th>
        <q-th :props="props" key="story_name">
          <span class="text-bold">{{ $t('label.story.rate_answered_qa') }}</span>
        </q-th>
        <q-th :props="props" key="story_name">
          <span class="text-bold">{{ $t('label.story.click_contents_count') }}</span>
        </q-th>
        <q-th :props="props" key="created_at">
          <span class="text-bold">{{ $t('label.created_at') }}</span>
        </q-th>
        <q-th :props="props" key="creator_name">
          <span class="text-bold">{{ $t('label.story.creator_name') }}</span>
        </q-th>
        <q-th :props="props" key="updated_at">
          <span class="text-bold">{{ $t('label.updated_at') }}</span>
        </q-th>
        <q-th :props="props" key="updater_name">
          <span class="text-bold">{{ $t('label.story.updater_name') }}</span>
        </q-th>
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props" :index="props.rowKey">
        <q-td>
          <q-toggle
            round
            v-model="props.row.is_active"
            val="props.row.is_active"
            @click="handleToggleActive(props.row)"
          />
        </q-td>
        <q-td>
          <q-toggle
            round
            v-model="props.row.is_test_mode"
            val="props.row.is_test_mode"
            @click="handleToggleTest(props.row)"
          />
        </q-td>
        <q-td key="trigger_type" :props="props">
          <q-chip :class="'story-type-' + props.row.trigger_type">
            {{ $t('label.story.' + props.row.trigger_type + '_trigger') }}
          </q-chip>
        </q-td>
        <q-td class="text-center">
          <q-btn size="sm" flat round icon="edit" :to="onEdit(props.row)" class="q-mr-sm" />
          <q-btn size="sm" flat round icon="content_copy" class="q-mr-sm">
            <q-menu>
              <q-list style="min-width: 100px">
                <q-item clickable v-close-popup @click="onCopy(props.row, 'normal')">
                  <q-item-section>同じトリガーで複製</q-item-section>
                </q-item>
                <q-item
                  clickable
                  v-close-popup
                  @click="onCopy(props.row, 'default')"
                  v-if="props.row.trigger_type === 'popup_deliver'"
                >
                  <q-item-section>デフォルトに変換して複製</q-item-section>
                </q-item>
                <q-item
                  clickable
                  v-close-popup
                  @click="onCopy(props.row, 'popup_deliver')"
                  v-if="props.row.trigger_type === 'default'"
                >
                  <q-item-section>ポップアップに変換して複製</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
          <q-btn size="sm" flat round color="red" icon="delete" @click="onDelete(props.row)" class="q-mr-sm" />
          <q-btn size="sm" flat round icon="visibility" @click="onPreview(props.row)" class="q-mr-sm" />
          <q-btn size="sm" flat round @click="onTransmissionLog(props.row)" :label="$t('label.story.log')" />
        </q-td>
        <q-td key="story_name" :props="props">
          <span>{{ props.row.story_name }}</span
          ><br />
          <a
            :href="getDetailsUrl(props.row)"
            @click="onDetails($event, props.row)"
            class="cursor-pointer hyper-link-chart"
          >
            {{ $t('label.data_transition') }}
          </a>
          <span>/{{ props.row._id.length > 4 ? props.row._id.slice(0, 4) + '***' : props.row._id }}</span>
        </q-td>
        <q-td key="story_name" :props="props">
          <span>{{ numberWithCommas(props.row.start_story_count) }}</span>
        </q-td>
        <q-td key="story_name" :props="props">
          <span>{{ numberWithCommas(props.row.send_qa_count) }}</span>
        </q-td>
        <q-td key="story_name" :props="props">
          <span>{{ numberWithCommas(props.row.answered_qa_count) }}</span>
        </q-td>
        <q-td key="story_name" :props="props">
          <span>{{ roundNumber(props.row.rate_answered_qa) }}%</span>
        </q-td>
        <q-td key="story_name" :props="props">
          <span>{{ numberWithCommas(props.row.click_contents_count) }}</span>
        </q-td>
        <q-td key="created_at" :props="props">
          <span>{{ props.row.created_at }}</span>
        </q-td>
        <q-td key="creator_name" :props="props">
          <span>{{ props.row.creator_name }}</span>
        </q-td>
        <q-td key="created_at" :props="props">
          <span>{{ props.row.updated_at }}</span>
        </q-td>
        <q-td key="updater_name" :props="props">
          <span>{{ props.row.updater_name }}</span>
        </q-td>
      </q-tr>
    </template>

    <template v-slot:top>
      <h2 class="text-h6 text-black">{{ $t('label.story.story_list') }}</h2>
      <q-space />
      <q-btn no-caps class="btn-save" @click="onAdd" :label="$t('label.story.add_new_story')" />
    </template>
  </q-table>
  <PreviewSelectorModal
    v-if="modalPreviewVisible"
    :modalVisible="modalPreviewVisible"
    :scenarios="campaignDetails[campaignIndex].scenarios"
    @update:closeModal="onClosePreviewModal"
    @nextCampaign="onNextCampaign"
  />

  <TransmissionLogModal
    v-if="transmissionLogModalVisible"
    :modalVisible="transmissionLogModalVisible"
    :selectedStory="selectedStory"
    @update:closeModal="onCloseModal"
  />
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { IInitialCampaign, IInitialCampaignDetail, IPushCampaign, IPushCampaignDetail, IStory } from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import {
  ACTION_GOAL,
  ACTION_INITIAL,
  ACTION_MESSAGES,
  ACTION_PUSH,
  ACTION_QUESTION,
  ACTION_STORY,
} from '@/store/actions'

import { constant, TRIGGER_TYPE } from '@/utils/constants'
import CLoading from '@/components/common/ui/CLoading.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import PreviewSelectorModal from '@/components/scenarios/selectors/PreviewSelectorModal.vue'
import { Formater } from '@/utils/formater'
import TransmissionLogModal from '@/components/story/TransmissionLogModal.vue'
import { lastNMonth, now } from '@/utils/datetime'

@Options({
  components: { PreviewSelectorModal, CLoading, TransmissionLogModal },
  directives: { maska },
  emits: [],
})
export default class StoryList extends mixins(BaseFormMixin) {
  loading = true
  modalPreviewVisible = false
  campaignIndex = 0
  campaignDetails: IInitialCampaignDetail[] | IPushCampaignDetail[] = []
  initialCampaigns: IInitialCampaign[] = []

  transmissionLogModalVisible = false
  selectedStory!: IStory

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get stories() {
    return cloneDeep(this.$store.getters.stories) || []
  }

  @Watch('selectedAppId', { immediate: true })
  async appIdChanged() {
    if (!this.selectedAppId) {
      return
    }

    await this.$store.dispatch(ACTION_STORY.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    await this.$store.dispatch(ACTION_QUESTION.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    await this.$store.dispatch(ACTION_GOAL.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    await this.$store.dispatch(ACTION_MESSAGES.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    this.initialCampaigns = await this.$store.dispatch(ACTION_INITIAL.LOAD_ITEMS_WITHOUT_DETAIL, {
      app_id: this.selectedAppId,
    })

    this.loading = false
  }

  async updateIsActive(item: IStory, isTestMode: boolean) {
    let isSuccess = false
    if (isTestMode) {
      isSuccess = await this.$store.dispatch(ACTION_STORY.TEST_MODE, item)
    } else {
      isSuccess = await this.$store.dispatch(ACTION_STORY.IS_ACTIVE, item)
    }

    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async handleToggleActive(record: IStory) {
    if (record.is_active && record.trigger_type === TRIGGER_TYPE.DEFAULT) {
      const initial: IInitialCampaign = await this.$store.dispatch(ACTION_INITIAL.LOAD_STORY_ITEM, record._id)
      console.log(initial, 'initial')
      let message = ''
      if (
        initial &&
        (initial._id === '' || initial._id === undefined || initial.prod_detail?.scenarios?.length === 0)
      ) {
        message = this.$t('messages.display_an_alert_prompting_the_user_to_create_a_message')
      }

      const scenarios = initial.prod_detail?.scenarios
      if (scenarios) {
        for (const element of scenarios) {
          if (element.id !== 'start' && element.id === '') {
            message = this.$t('messages.display_an_alert_prompting_the_user_to_create_a_message')
            break
          }
        }
      }
      if (message === '') {
        const resp = await this.$store.dispatch(ACTION_STORY.VALIDATE_DEFAULT_TYPE, {
          app_id: record.app_id,
          is_test_mode: false,
        })
        // eslint-disable-next-line
        if (resp && resp['result']) {
          message = this.$t('messages.display_an_alert_that_only_one_can_be_active')
        }
      }
      if (message !== '') {
        this.$q.dialog({
          title: 'ご確認下さい',
          message: message,
          persistent: true,
          html: true,
          class: 'q-dialog-plugin-custom',
        })

        record.is_active = false
        return
      }
    }

    if (!record.is_active) {
      await this.updateIsActive(record, false)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
          cancel: {
            flat: true,
            label: this.$t('messages.no'),
          },
          ok: {
            flat: true,
            label: this.$t('messages.yes'),
          },
          persistent: true,
        })
        .onOk(async () => {
          await this.updateIsActive(record, false)
        })
        .onCancel(async () => {
          record.is_active = false
        })
    }
  }

  async handleToggleTest(record: IStory) {
    if (record.is_test_mode && record.trigger_type === TRIGGER_TYPE.DEFAULT) {
      let message = ''
      const initial: IInitialCampaign = await this.$store.dispatch(ACTION_INITIAL.LOAD_STORY_ITEM, record._id)
      console.log(initial, 'initial')
      if (
        initial &&
        (initial._id === '' || initial._id === undefined || initial.test_detail?.scenarios?.length === 0)
      ) {
        message = this.$t('messages.display_an_alert_prompting_the_user_to_create_a_message')
      }

      const scenarios = initial.test_detail?.scenarios
      if (scenarios) {
        for (const element of scenarios) {
          if (element.id !== 'start' && element.id === '') {
            message = this.$t('messages.display_an_alert_prompting_the_user_to_create_a_message')
            break
          }
        }
      }
      if (message === '') {
        const resp = await this.$store.dispatch(ACTION_STORY.VALIDATE_DEFAULT_TYPE, {
          app_id: record.app_id,
          is_test_mode: true,
        })
        // eslint-disable-next-line
        if (resp && resp['result']) {
          message = this.$t('messages.display_an_alert_that_only_one_can_be_active')
        }
      }
      if (message !== '') {
        this.$q.dialog({
          title: 'ご確認下さい',
          message: message,
          persistent: true,
          html: true,
          class: 'q-dialog-plugin-custom',
        })

        record.is_test_mode = false
        return
      }
    }

    await this.updateIsActive(record, true)
  }

  onAdd() {
    this.goto('story_setting', {
      app_id: this.selectedAppId,
      action_type: constant.ACTION_TYPE.ADD,
    })
  }

  async onCopy(item: IStory, type: string) {
    this.goto('story_setting', {
      app_id: this.selectedAppId,
      story_id: item._id,
      action_type: constant.ACTION_TYPE.COPY,
      copy_type: type,
    })
  }

  onEdit(data: IStory) {
    return this.detailPath('story_setting', {
      app_id: this.selectedAppId,
      story_id: data._id,
      action_type: constant.ACTION_TYPE.EDIT,
    })
  }

  async onPreview(story: IStory) {
    if (
      story.trigger_type === TRIGGER_TYPE.POPUP_DELIVER ||
      story.trigger_type === TRIGGER_TYPE.QR_CODE ||
      story.trigger_type === TRIGGER_TYPE.DEFAULT
    ) {
      const initial: IInitialCampaign = await this.$store.dispatch(ACTION_INITIAL.LOAD_STORY_ITEM, story._id)
      if (initial._id !== '' && initial.prod_detail) {
        this.campaignDetails = [...this.campaignDetails, initial.prod_detail]
      }
      const pushes: IPushCampaign[] = await this.$store.dispatch(ACTION_PUSH.LOAD_STORY_ITEMS, story._id)
      for (const push of pushes) {
        if (push.prod_detail) {
          this.campaignDetails = [...this.campaignDetails, push.prod_detail]
        }
      }
    }
    if (this.campaignDetails.length) {
      this.modalPreviewVisible = true
    }
  }

  onClosePreviewModal() {
    this.campaignDetails = []
    this.campaignIndex = 0
    this.modalPreviewVisible = false
  }

  onNextCampaign() {
    if (this.campaignIndex < this.campaignDetails.length - 1) {
      this.campaignIndex++
    }
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  detailPath(name: string, params = {}) {
    return {
      name: name,
      params: params,
    }
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  roundNumber(num) {
    return Formater.round(num)
  }

  onTransmissionLog(data) {
    this.transmissionLogModalVisible = true
    this.selectedStory = data
  }

  onCloseModal() {
    this.transmissionLogModalVisible = false
  }

  onDetails(event, data: IStory) {
    // Check if Ctrl (Windows) or Meta (Mac) is pressed
    if (event.ctrlKey || event.metaKey) {
      // Let the browser handle opening in a new tab
      return
    }
    // Otherwise, navigate programmatically
    event.preventDefault()

    this.goto('story_details', {
      app_id: this.selectedAppId,
      campaign_type: 'initial',
      story_id: data._id,
      from_date: lastNMonth(1),
      to_date: now(),
    })
  }

  getDetailsUrl(data) {
    // Construct the URL as a string for direct navigation
    return this.$router.resolve({
      name: 'story_details',
      params: {
        app_id: this.selectedAppId,
        campaign_type: 'initial',
        story_id: data._id,
        from_date: lastNMonth(1),
        to_date: now(),
      },
    }).href
  }

  async onDelete(data: IStory) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const success = await this.$store.dispatch(ACTION_STORY.DELETE, data._id)
        if (success) {
          this.$q.notify({
            message: this.$t('messages.deleted'),
            color: 'positive',
          })
        }
      })
  }
}
</script>
<style scoped lang="scss">
</style>
