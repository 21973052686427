<template>
  <section>
    <CLoading :loading="loading" />
    <div class="row">
      <div class="col-6">
        <q-input bottom-slots v-model="searchName" :label="$t('search')" dense @keydown.enter="searchMedia">
          <template v-slot:append>
            <q-btn round dense flat icon="search" @click="searchMedia" />
          </template>
        </q-input>
      </div>
      <div class="col-6" align="right">
        <q-btn no-caps icon="cloud_upload" class="btn-upload" @click="isAdd = true" :label="$t('upload')" />
      </div>
    </div>
    <div class="content">
      <div class="row q-col-gutter-lg">
        <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2" v-for="(item, index) in media" :key="index">
          <q-card class="my-card">
            <q-img :src="item.thumb" @click="selectMedia(item)">
              <div class="absolute-bottom">
                <div class="text-subtitle2">{{ item.name }}</div>
              </div>
            </q-img>
          </q-card>
        </div>
      </div>
    </div>
    <div v-if="totalPages" class="q-pa-lg flex pagination items-center justify-end">
      <span class="text-caption q-pr-sm text-grey-8">{{ $t('pagination.records_per_page') }}: </span
      ><q-select borderless v-model="perPage" :options="perPageOptions" />
      <q-pagination v-model="currentPage" :max="totalPages" color="grey" input />
    </div>
    <q-dialog v-model="isSelectMedia" full-height position="right">
      <MediaDetails :selected-media="selectedMedia" :isReviewImage="true"></MediaDetails>
    </q-dialog>
    <q-dialog v-model="isAdd" full-height position="right">
      <q-card class="column" style="width: 1000px; height: 800px; max-height: 60vh; max-width: 90vw">
        <q-toolbar>
          <q-toolbar-title> {{ modalTitle }} </q-toolbar-title>
          <q-btn flat round dense icon="close" @click="onCancel" />
        </q-toolbar>
        <q-card-section class="col">
          <UploaderComponent
            ref="uploaderComponent"
            :is-crop-image="false"
            :accept-file-types="'image/*, video/*'"
            @on-uploaded-file="onUploadedFiles"
          ></UploaderComponent>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn no-caps flat class="btn-cancel" @click="onCancel">{{ $t('cancel') }}</q-btn>
          <q-btn no-caps class="btn-save" @click="submitMedia"> {{ $t('save') }} </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </section>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import CLoading from '@/components/common/ui/CLoading.vue'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { ACTION_MEDIA } from '@/store/actions'
import { IMedia } from '@/utils/types'
import MediaDetails from '@/components/media-manager/MediaDetails.vue'
import UploaderComponent from '@/components/media-manager/UploaderComponent.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
@Options({
  components: {
    UploaderComponent,
    MediaDetails,
    CLoading,
  },
  directives: { maska },
})
export default class MediaManager extends mixins(BaseFormMixin) {
  loading = true
  isAdd = false
  isSelectMedia = false
  searchName = ''
  currentPage = 1
  selectedMedia: IMedia = {}
  media: IMedia[] = []
  totalPages = 0
  perPage = 12
  perPageOptions = [12, 24, 36, 60]

  get appId() {
    return this.$route.params.app_id
  }

  get modalTitle() {
    return this.$t('media_upload')
  }

  @Watch('appId', { immediate: true })
  async appIdChanged() {
    if (!this.appId) {
      return
    }
    this.loading = true
    await this.fetchMedia()
  }

  @Watch('currentPage')
  async currentPageChanged() {
    this.loading = true
    await this.fetchMedia()
  }

  @Watch('perPage')
  async currentPerPageChanged() {
    this.loading = true
    await this.fetchMedia()
  }

  async searchMedia() {
    this.loading = true
    await this.fetchMedia()
  }

  selectMedia(item) {
    this.isSelectMedia = true
    this.selectedMedia = item
  }

  async submitMedia() {
    this.$refs.uploaderComponent.uploadFiles()
  }

  async fetchMedia() {
    const result = await this.$store.dispatch(ACTION_MEDIA.LOAD_ITEMS, {
      app_id: this.appId,
      filter: {
        name: this.searchName,
        page: this.currentPage,
        per_page: this.perPage,
      },
    })
    this.media = result.items
    this.totalPages = result.total_pages

    this.loading = false
  }

  async onUploadedFiles(files) {
    const { dispatch } = this.$store
    if (files.length) {
      this.isAdd = false
      this.loading = true
      await dispatch(ACTION_MEDIA.ADD, files)
      await this.fetchMedia()
    }
  }

  onCancel() {
    this.isAdd = false
  }
}
</script>

<style scoped lang="scss">
:deep(.pagination .q-field__control) {
  background: none;
}
:deep(.q-uploader) {
  min-height: 100%;
}
</style>
