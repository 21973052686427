import { render } from "./MediaDetails.vue?vue&type=template&id=9a92d556&scoped=true"
import script from "./MediaDetails.vue?vue&type=script&lang=ts"
export * from "./MediaDetails.vue?vue&type=script&lang=ts"

import "./MediaDetails.vue?vue&type=style&index=0&id=9a92d556&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-9a92d556"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QImg,QCardActions,QBtn});
