<template>
  <q-dialog v-model="visible">
    <q-card class="full-card">
      <q-toolbar class="auto-height horizontal-line">
        <q-toolbar-title class="q-pa-sm"> ユーザー選択 </q-toolbar-title>
        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>
      <q-card-section class="q-pa-none horizontal-line">
        <CLoading :loading="loading" />
        <div class="row horizontal-line">
          <div class="col-9 q-px-md q-pt-md">
            <div class="row">
              <q-input
                outlined
                dense
                v-model="search"
                @update:model-value="onSearchUsers"
                debounce="1000"
                label="ユーザー名で検索"
                class="full-width"
              >
                <template v-slot:append>
                  <q-icon name="search" color="text-prey-3" />
                </template>
              </q-input>
            </div>
            <div class="row justify-end items-baseline q-gutter-xs q-mt-xs">
              <span class="text-subtitle2 text-grey-8 text-bold"> {{ displayUsers.length }} / {{ totalUsers }} </span>
              <span class="text-grey-8" style="font-size: 8px">名を表示</span>
            </div>
          </div>
          <div class="col q-px-md q-pt-md">
            <div class="text-subtitle2 text-grey-8 text-bold">選択済みユーザー</div>
            <div class="row justify-end items-baseline q-gutter-xs q-mt-xs">
              <span class="text-subtitle2 text-grey-8 text-bold">
                {{ selectAll ? totalUsers : selectedUserIds.length }}
              </span>
              <span class="text-grey-8" style="font-size: 8px">名を選択しました。</span>
            </div>
          </div>
        </div>
        <div class="flex column justify-between">
          <div>
            <div class="row q-px-md q-py-sm horizontal-dotted-line justify-between">
              <div class="row q-gutter-sm">
                <q-btn
                  outline
                  dense
                  label="すべて選択"
                  class="btn-open"
                  :class="selectAll ? 'btn-save-grey' : 'btn-cancel'"
                  @click="toggleSelectAll(true)"
                />
                <q-btn
                  outline
                  dense
                  label="すべて解除"
                  class="btn-open"
                  :class="!selectAll ? 'btn-save-grey' : 'btn-cancel'"
                  @click="toggleSelectAll(false)"
                />
              </div>
              <q-select
                outlined
                dense
                v-model="selectedUserState"
                :options="userStateOptions"
                emit-value
                map-options
                class="cus-select"
              />
            </div>
            <div class="row q-px-md q-py-sm" v-if="selectedUserState === 'all'">
              <div
                class="row col-3 q-gutter-sm items-center q-mb-md"
                v-for="(user, index) in users"
                :key="user._id + index"
              >
                <q-checkbox dense v-model="selectedUserIds" :val="user.user_id" @click="toggleUser" color="teal" />
                <UserAvatar :size="24" :user="getUserInfo(user.display_name, user.picture_url)" />
                <span class="q-ml-sm">{{ truncate(user.display_name, 6, '...') }}</span>
                <q-tooltip>{{ user.display_name }}</q-tooltip>
              </div>
            </div>
            <div class="row q-px-md q-py-sm" v-else>
              <div
                class="row col-3 q-gutter-sm items-center q-mb-md"
                v-for="(user, index) in filterUsers"
                :key="user._id + index"
              >
                <q-checkbox dense v-model="selectedUserIds" :val="user.user_id" @click="toggleUser" color="teal" />
                <UserAvatar :size="24" :user="getUserInfo(user.display_name, user.picture_url)" />
                <span class="q-ml-sm">{{ truncate(user.display_name, 6, '...') }}</span>
                <q-tooltip>{{ user.display_name }}</q-tooltip>
              </div>
            </div>
          </div>
          <div class="flex justify-end horizontal-dotted-line-top" v-if="selectedUserState === 'all'">
            <q-pagination v-model="currentPage" :max="totalPages" color="grey" input />
          </div>
          <div class="flex justify-end horizontal-dotted-line-top" v-else>
            <q-pagination v-model="currentFilterPage" :max="totalFilterPages" color="grey" input />
          </div>
        </div>
      </q-card-section>
      <q-card-actions align="right" class="q-pa-md">
        <q-btn no-caps class="btn-save-grey" v-close-popup>{{ $t('cancel') }}</q-btn>
        <q-btn no-caps class="btn-save" @click="onSubmit"> {{ $t('save') }} </q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang='ts'>
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { Prop, Watch } from 'vue-property-decorator'
import UserAvatar from '@/components/user/UserAvatar.vue'
import { IUser } from '@/utils/types'
import CLoading from '@/components/common/ui/CLoading.vue'
@Options({
  components: { CLoading, UserAvatar },
  directives: { maska },
  emits: ['update:closeModal', 'update:submitUsers', 'update:loadUsers', 'update:filterUsers'],
})
export default class UserSelectionModal extends Vue {
  @Prop()
  modalVisible!: boolean

  @Prop({})
  users!: IUser[]

  @Prop({})
  filterUsers!: IUser[]

  @Prop()
  totalPages!: number

  @Prop()
  totalFilterPages!: number

  @Prop()
  totalUsers!: number

  @Prop()
  perPages!: number

  @Prop()
  loading!: boolean

  currentPage = 1
  currentFilterPage = 1
  search = ''
  selectAll = true
  selectedUserIds: (string | undefined)[] = []
  selectedUserState = 'all'
  userStateOptions = [
    {
      label: 'すべて',
      value: 'all',
    },
    {
      label: '選択済み',
      value: 'selected',
    },
    {
      label: '未選択',
      value: 'unselected',
    },
  ]

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get displayUsers() {
    if (this.selectedUserState === 'all') {
      return this.users
    }

    return this.filterUsers
  }

  @Watch('currentPage')
  async currentPageChanged(page) {
    const filter = {
      page: page ?? this.currentPage,
      per_page: this.perPages,
      display_name: this.search,
      state: this.selectedUserState,
      user_ids: this.selectedUserIds,
      is_all: this.selectAll,
    }
    this.$emit('update:loadUsers', filter)
  }

  @Watch('users')
  @Watch('filterUsers')
  userChanged() {
    let currentUserIds: (string | undefined)[]
    if (this.selectAll) {
      currentUserIds = this.users.map((user) => user.user_id)
    } else {
      currentUserIds = []
    }

    if (this.totalPages === 0) {
      this.currentPage = 0
    }

    if (this.totalFilterPages === 0) {
      this.currentFilterPage = 0
    }

    this.selectedUserIds = [...new Set([...this.selectedUserIds, ...currentUserIds])]
  }

  @Watch('selectedUserState')
  @Watch('currentFilterPage')
  selectedUserStateChanged() {
    if (this.selectedUserState !== 'all') {
      const filter = {
        page: this.currentFilterPage,
        per_page: this.perPages,
        display_name: this.search,
        state: this.selectedUserState,
        user_ids: this.selectedUserIds,
        is_all: this.selectAll,
      }
      this.$emit('update:filterUsers', filter)
    }
  }

  toggleSelectAll(val) {
    this.selectAll = val
    if (this.selectAll) {
      this.selectedUserState = 'all'
      this.selectedUserIds = this.users.map((user) => user.user_id)
    } else {
      this.selectedUserIds = []
    }
  }

  toggleUser() {
    this.selectAll = this.selectedUserIds.length === this.totalUsers
  }

  onSearchUsers() {
    this.currentPageChanged(1)
  }

  onSubmit() {
    this.$emit('update:submitUsers', this.selectedUserIds, this.selectAll)
    this.visible = false
  }

  getUserInfo(display_name, picture_url) {
    const u: IUser = {
      photo_url: picture_url,
      display_name: display_name,
    }
    return u
  }

  truncate(text, length, clamp) {
    clamp = clamp || '...'
    const node = document.createElement('div')
    node.innerHTML = text
    const content = node.textContent
    if (content) {
      return content.length > length ? content.slice(0, length) + clamp : content
    }
    return text
  }
}
</script>

<style scoped lang='scss'>
.horizontal-dotted-line {
  border-bottom: 1px dotted #d0d2e0;
}
.horizontal-dotted-line-top {
  border-top: 1px dotted #d0d2e0;
}
.btn-open {
  padding: 2px 6px;
  :deep(span) {
    font-weight: unset;
    line-height: unset;
    font-size: unset;
    letter-spacing: unset;
  }
}
:deep(.q-btn--outline:before) {
  border-color: #d0d2e0;
}
.cus-select {
  width: 120px;
  height: 28px !important;
  :deep(div) {
    font-weight: unset;
    line-height: unset;
    font-size: unset;
    letter-spacing: unset;
    height: unset;
    min-height: unset !important;
    .q-field__control {
      height: 30px;
    }
  }
}
</style>
