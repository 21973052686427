
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IDateRangePicker, IUser } from '@/utils/types'
import { ACTION_APP_USER } from '@/store/actions'
import DateRangeSelectorV2 from '@/components/common/ui/DateRangeSelectorV2.vue'
import UserSelectionModal from '@/components/story/common/selectors/UserSelectionModal.vue'
import { DatetimeUtils } from '@/utils/datetime'

@Options({
  components: { DateRangeSelectorV2, UserSelectionModal },
  directives: { maska },
  emits: ['update:modelValue', 'update:queryParams'],
})
export default class TransmissionSelector extends Vue {
  @Prop({})
  resources!: []

  @Prop()
  appId!: string

  @Prop()
  isTestMode!: boolean

  dateRange: IDateRangePicker = {
    startDate: '',
    endDate: '',
  }

  currentDateType = ''
  selectedResources = []
  currentResourceType = ''
  selectedPhases = []
  currentPhase = ''
  selectedUsers: (string | undefined)[] = []
  phases = [
    {
      code: 'waiting',
      label: '予定',
    },
    {
      code: 'succeeded',
      label: '成功',
    },
    {
      code: 'error',
      label: '失敗',
    },
  ]

  modalVisible = false
  modalLoading = false
  defaultUsers = 'ユーザー'
  totalUsers = 0
  totalPages = 0
  totalFilterPages = 0
  perPages = 32
  users: IUser[] = []
  filterUsers: IUser[] = []

  onOpenModal() {
    this.modalVisible = true
  }

  onCloseModal() {
    this.modalVisible = false
  }

  onSubmitModal(val: [], all) {
    if (all) {
      this.selectedUsers = []
    } else {
      this.selectedUsers = val
    }

    this.updateParams()
  }

  async loadUsers(filter) {
    this.modalLoading = true
    const response = await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEMS, {
      app_id: this.appId,
      is_test_mode: this.isTestMode,
      filter: filter,
    })
    if (response) {
      if (filter.state === 'selected' || filter.state === 'unselected') {
        this.filterUsers = response.items
        this.totalFilterPages = response.total_pages
      } else {
        this.users = response.items
        this.totalPages = response.total_pages
      }
    }
    this.modalLoading = false
  }

  changeDate(type) {
    if (type === this.currentDateType) {
      this.currentDateType = ''
    } else {
      this.currentDateType = type
    }
  }

  changeResource(type) {
    if (type === this.currentResourceType) {
      this.currentResourceType = ''
    } else {
      this.currentResourceType = type
    }
  }

  changePhase(type) {
    if (type === this.currentPhase) {
      this.currentPhase = ''
    } else {
      this.currentPhase = type
    }
  }

  @Watch('currentDateType')
  setDateRange() {
    this.dateRange = DatetimeUtils.getDateRange(this.currentDateType)
    this.updateParams()
  }

  changeDateRange(value) {
    this.dateRange = value
    this.currentDateType = ''
    this.updateParams()
  }

  @Watch('currentResourceType')
  @Watch('selectedResources')
  @Watch('currentPhase')
  @Watch('selectedPhases')
  updateParams() {
    const params = {
      start_date: DatetimeUtils.formatDate(this.dateRange.startDate),
      end_date: DatetimeUtils.formatDate(this.dateRange.endDate),
      resource_type: this.currentResourceType,
      resources: this.selectedResources,
      phase_type: this.currentPhase,
      phases: this.selectedPhases,
      users: this.selectedUsers,
    }
    this.$emit('update:queryParams', params)
  }

  @Watch('isTestMode')
  async onChangeTestMode() {
    this.selectedUsers = []

    const all = await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEMS, {
      app_id: this.appId,
      is_test_mode: this.isTestMode,
      filter: {},
    })

    if (all) {
      this.totalUsers = all.total_items
    }

    const filter = {
      page: 1,
      per_page: this.perPages,
    }
    await this.loadUsers(filter)
    this.updateParams()
  }

  async created() {
    this.changeDate('thisMonth')
    await this.onChangeTestMode()
  }
}
