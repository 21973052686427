<template>
  <q-card :style="isReviewImage ? 'min-width: 40vw' : 'min-width: 20vw'">
    <q-img :src="selectedMedia.url" style="max-height: 800px" />
    <q-card-actions align="right">
      <q-btn flat round color="primary" icon="share" @click="copyToClipBoard(selectedMedia.url)" />
      <q-btn flat round color="teal" icon="download" @click="onDownload(selectedMedia.url, selectedMedia.name)" />
    </q-card-actions>
  </q-card>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IMedia } from '@/utils/types'
import { maska } from 'maska'

@Options({
  components: {},
  directives: { maska },
})
export default class MediaDetails extends Vue {
  @Prop()
  selectedMedia: IMedia = {}

  @Prop()
  isReviewImage!: boolean

  copyToClipBoard(textToCopy) {
    navigator.clipboard.writeText(textToCopy)
  }

  async onDownload(url, name) {
    const baseUrl = process.env.VUE_APP_API_ENDPOINT
    const proxyUrl = `${baseUrl}v1/proxy_image?url=` + encodeURIComponent(url)
    const response = await fetch(proxyUrl)
    //  const response = await fetch(url)
    const blob = await response.blob()
    // Determine extension from the blob's MIME type
    let extension = ''
    switch (blob.type) {
      case 'image/jpeg':
        extension = '.jpeg'
        break
      case 'image/jpg':
        extension = '.jpg'
        break
      case 'image/png':
        extension = '.png'
        break
      case 'image/gif':
        extension = '.gif'
        break
      // add more types as needed
      default:
        extension = ''
    }

    // Append extension if missing
    let fileName = name
    if (!fileName.includes('.') && extension) {
      fileName += extension
    }

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    // console.log(fileName, 'fileName')
    link.download = fileName
    link.click()
  }
}
</script>

<style scoped lang="scss">
:deep(.q-img__image) {
  width: auto !important;
}
:deep(.q-img__container.absolute-full) {
  text-align: center;
}
</style>
