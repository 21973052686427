import { render } from "./TransmissionSelector.vue?vue&type=template&id=60250044&scoped=true"
import script from "./TransmissionSelector.vue?vue&type=script&lang=ts"
export * from "./TransmissionSelector.vue?vue&type=script&lang=ts"

import "./TransmissionSelector.vue?vue&type=style&index=0&id=60250044&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-60250044"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QField from 'quasar/src/components/field/QField.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QSelect,QInput,QIcon,QField,QCard,QRadio});
